import Kevin from "../../../assets/Kevin.jpg";
import Ray from "../../../assets/Ray - Profile Photo.jpg";
import Shadrina from "../../../assets/Shadrina Wicaksono.jpg";

export const SCHEDULE = [
  { time: "12:30 - 01:00 PM", activity: "Open registration" },
  { time: "01:00 - 02:00 PM", activity: "Panel Session" },
  { time: "02:00 - 04:00 PM", activity: "Networking Session" },
  { time: "04:00 - 04:30 PM", activity: "Closing" },
];

export const PANELIST = [
  {
    name: "Ray Dwitama",
    image: Ray,
    role: "Management Assistant",
    remark: "",
    bio: "Ray is a passionate entrepreneur and manager, demonstrated by starting up his own mobile car wash business 3 years ago while being a student. His passion for leadership and client relations brought him to his current position at Enterprise Mobility which highlighted his ability to navigate complex operations with professionalism. Ray loves working on multiple projects, as he is also a part of the core team at @arisanvancouver. He is an avid coffee enthusiast who lives by the self-created motto, “no coffee = no work.” ",
    pastExperiences: [],
  },
  {
    name: "Shadrina Wicaksono",
    image: Shadrina,
    role: "Marketing Specialist",
    remark: "with past co-op & organizational experience",
    bio: "Shadrina is a strategic marketer with 5 years of experience spanning SaaS, higher education, e-commerce, sports, and financial service. During her academic days, Shadrina dedicated four years to GISAU and later helped kickstart PERMIKA and IPA Vancouver as a founding member. In her down time, Shadrina channels her creativity through singing, songwriting, and thrifting for the soul!  ",
    pastExperiences: [
      {
        position: "Events Marketing Co-op",
        organization: "Hootsuite",
        year: "2018 - 2018",
      },
      {
        position: "Vice President",
        organization: "GISAU",
        year: "2018 - 2019",
      },
      {
        position: "Founding Member",
        organization: "PERMIKA Vancouver",
        year: "2018 - 2019",
      },
    ],
  },
  {
    name: "Gratianus Deodatus Kavin",
    image: Kevin,
    role: "Account Manager",
    remark: "with past organizational experience",
    bio: "Deodatus is a professional account manager blending his economics background from SFU with a natural flair for connection. An extrovert at heart, his love for people fuels his passion for food, helping him thrive in the seafood industry. During his studies, he dedicated his time to organizing engaging events for SFUIA, honing his skills in networking and collaboration. Known as a big foodie, you can find Deodatus exploring the latest culinary hotspots and sharing his food adventures with friends and colleagues.",
    pastExperiences: [
      {
        position: "Event Coordinator",
        organization: "Mudika Vancouver",
        year: "2017 - 2019",
      },
      {
        position: "Creative DIRECTOR",
        organization: "SFUIA",
        year: "2018 - 2020",
      },
    ],
  },
];

export const DELEGATES = [
  {
    name: "Panelist name 1",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
  {
    name: "Panelist name",
    image: "https://placehold.co/600x400/EEE/31343C",
    jobTitle: "Job Title",
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
    pastExperiences: [
      {
        position: "Position",
        organization: "Organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
      {
        position: "Position",
        organization: "organization",
        year: "2020 - 2022",
      },
    ],
  },
];
